// Integrate Sentry.
import * as Sentry from "@sentry/react";
Sentry.init({
	enabled: process.env.NODE_ENV === "production",
	
	dsn: "https://f32314e7a45e09c70864661db2853776@o4508601773654016.ingest.de.sentry.io/4508607604129872",
	
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
		Sentry.inboundFiltersIntegration({
			allowUrls: ["mozart-bett.de"]
		}),
	],
	
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import "./styles/global.scss";

import "./features/bed-configurator/bootstrap";
import "./features/sample-box-picker/bootstrap";
import "./features/bed-images/bootstrap";
import "./features/bed-images-new/bootstrap";
import "./features/sale-box/bootstrap";
import "./features/sale/fabric-sale-box/bootstrap";
import "./features/mozart-firmness-quiz/bootstrap";
import "./features/zip-questionnaire-modal/bootstrap";
import "./features/accessories-buy-box/bootstrap";
import "./features/save-user/bootstrap";
import "./features/bed-color-picker/bootstrap";
